<div class="grid">
	<div class="col-12">

		<div class="card sm: p-0" id="usersTableDiv">
			
			<div class="flex justify-content-between align-items-center pb-2 w-full">

				<div class="sm: ml-3 sm: mt-3">
					<!--<p-multiSelect 
						class="mr-2"
						[options]="tableColumns" 
						[(ngModel)]="selectedColumns" 
						optionLabel="header"
						[showHeader]="false"
						selectedItemsLabel="Columns" 
						[style]="{'width': '10rem'}" placeholder="Columns"></p-multiSelect>	-->
					<span class="p-input-icon-left mb-2 mr-2 ">
						<i class="pi pi-search "></i>
						<input pInputText type="text" #filter (input)="onGlobalFilter(dt1, $event)" class="w-12rem"/>
					</span>
					
				</div>
			</div>

			<p-table #dt1 
			[columns]="tableColumns"
			[value]="data" 
			[rows]="100" 
			[tableStyle]="{'width': '100%'}" 
			[paginator]="true" 
			[globalFilterFields]="['name','phone']" 
			selectionMode="single" 
			[(selection)]="selectedRow"
			dataKey="_id" 
			styleClass=""
			responsiveLayout="scroll">
				
				<ng-template pTemplate="header" let-columns>
					<tr>
						<ng-container *ngFor="let col of columns">
							<th  style="width: 10rem" >
								{{ col.header }}
							</th>	
						</ng-container>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
					<tr  [pSelectableRow]="rowData" style="cursor: pointer" (click)="onRowClicked()">
						<td  *ngFor="let col of columns" >
							{{rowData[col.field] }}
							<span *ngIf="!rowData[col.field]" class="text-gray-200">―</span>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">Nothing to display</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="loadingbody">
					<tr>
						<td colspan="8">Loading...</td>
					</tr>
				</ng-template>
    		</p-table>
		</div>

	</div>
</div>

<p-table  [value]="data" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{ col.header }}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                {{ rowData[col.field] }}
            </td>
        </tr>
    </ng-template>
</p-table>

<p-sidebar 
	(onHide)="onSidebarClosed()" 
	[(visible)]="showTimelineSidebar" 
	[style]="{width: getSidebarWidth()}" 
	position="right" 
	[baseZIndex]="10000"
	[showCloseIcon]="true"
	[modal]="false"
	[blockScroll]="true"
>

	<!-- <app-client 
		[userId]="selectedRow?._id" 
		[userInfo]="selectedRow" 
		class="flex flex-column flex-grow-1 h-full"></app-client> -->
	
	<!-- <app-client class="flex flex-column flex-grow-1 h-full"></app-client> -->

	<ng-template pTemplate="header" styleClass="pb-2 lg:pb-4">
		<span class="text-900 font-semibold block">{{selectedRow.name}}</span>
		<p-inputSwitch class="ml-3" 
			[(ngModel)]="selectedRow.enable_bot"
			(onChange)="disableBotForCurrentClient(!$event.checked)"
		></p-inputSwitch>
	</ng-template>
	<ng-template pTemplate="content">
		<router-outlet></router-outlet>
	</ng-template>
	

</p-sidebar>
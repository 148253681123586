import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { RippleModule } from 'primeng/ripple';
import { MultiSelectModule } from 'primeng/multiselect';

import { SliderModule } from 'primeng/slider';
import { RatingModule } from 'primeng/rating';
import { ContextMenuModule } from 'primeng/contextmenu';

import { OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { AtlasService } from "../atlas.service";
import { MenuItem } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { TimelineComponent } from '../timeline/timeline.component';
import { ProfileComponent } from '../profile/profile.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputSwitchModule } from 'primeng/inputswitch';

import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SplitterModule } from 'primeng/splitter';
import { ClientComponent } from '../client/client.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-clients',
  standalone: true,
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss',
  imports: [
    TimelineComponent,
    ProfileComponent,
    ClientComponent,
    InputSwitchModule,
    SplitterModule,
    SidebarModule,
    CommonModule,
    ContextMenuModule,
    FormsModule,
    TableModule,
    RatingModule,
    ButtonModule,
    SliderModule,
    InputTextModule,
    ToggleButtonModule,
    RippleModule,
    MultiSelectModule,
    SplitButtonModule
  ]
})
export class ClientsComponent {
  @ViewChild('filter') filter!: ElementRef;

  loading: boolean = true;
  data: any[] = [];

  tableColumns = [
    { field: 'name', header: 'Name', show: true },
    { field: 'followers', header: 'Followers', show: true },
    { field: 'last_message_date', header: 'Last Message', show: true },
    // { field: '_id', header: 'ID', show: false },
  ];

  sendButtonItems: MenuItem[] = [
    { label: 'Comment', icon: 'pi pi-refresh' },
    { separator: true },
    { label: 'Setup', icon: 'pi pi-cog' }
  ];

  selectedRow: any = {};

  mongodbClients:any|null = null;

  constructor(
    private atlas: AtlasService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private titleService: Title,
    private sanitizer:DomSanitizer
  ) { }

  async ngOnInit() {

    this.mongodbClients = await this.atlas.getAllClients();
    //this.mongodbClients = await this.atlas.getClientsFromAds();
    this.updateClientsList();

    this.loading = false;

    this.titleService.setTitle("Clients | BotAdmin");

    var t = this;
    this.atlas.watchForClientsUpdates(function(newClient){
      t.mongodbClients.unshift(newClient);
      t.updateClientsList();
    });

    // Watch for client ID on first load (if we have URL with clientID)
    this.route.firstChild?.params.subscribe(params => {
      var clientId = params['id'];

      console.log("Got clientId in URL param: ",clientId);
      this.showSidebar(true);
      
      // Highlighting current user
      for (var row in this.data){
        if(this.data[row]._id == clientId){
          this.selectedRow = this.data[row];
          this.onRowClicked();
          break;
        }
      }

    });
    
    // Handling Back/Forward browser buttons
    this.router.events.subscribe(event => {
      
      if (event instanceof NavigationEnd) {
        console.log("Navigation: ", event.url, event);

        if (event.url === '/clients'){
          this.showSidebar(false);
         this.selectedRow=null;
        }else{
          this.showSidebar(true);

          const urlParts = event.url.split('/');
          const clientId = urlParts[urlParts.length - 1];

          console.log("Navigating to clientId: ",clientId);
          
          // Highlighting current user
          for (var row in this.data){
            if(this.data[row]._id == clientId){
              this.selectedRow = this.data[row];
              this.atlas.currentClient = this.selectedRow;
              break;
            }
          }
        }


      }
    });
  }


  onRowClicked() {
    console.log("rowClicked: " + this.selectedRow._id);

    this.atlas.currentClient = this.selectedRow;
    this.router.navigate(["/clients",this.selectedRow._id.toString()]);
    this.showSidebar(true);
  }


  showTimelineSidebar = false;
  showSidebar(show: boolean) {
    this.showTimelineSidebar = show;

    if (show && window.innerWidth <= 768) {
      document.body.classList.add('no-scroll-hack');
      document.getElementById("usersTableDiv")?.classList.add('hidden');
    }


  }

  onSidebarClosed() {
    console.log("closing sidebar");
    this.router.navigate(["/clients"]);

    if (window.innerWidth <= 768) {
      document.body.classList.remove('no-scroll-hack');
      document.getElementById("usersTableDiv")?.classList.remove('hidden');
    }
  }

  // Method to determine the width of the sidebar
  getSidebarWidth(): string {
    // Check if the screen width is less than or equal to 768 pixels
    if (window.innerWidth <= 768) {
      return '100%'; // Mobile: Full width
    } else {
      return 'calc(100% - 300px)'; // Desktop: 
    }
  }




  updateClientsList(){
    this.data = [];
    if (this.mongodbClients) {
      this.mongodbClients.forEach((item: any) => {

        var avatarUrl: SafeUrl="/assets/default_avatar.jpg";

        if(item.ig_profile?.profile_pic)
          avatarUrl= this.sanitizer.bypassSecurityTrustResourceUrl(item.ig_profile.profile_pic);

        if (item.ig_profile?.name)
          item.name = item.ig_profile.name;

        this.data.push({
          "_id": item._id,
          "name": item.name,
          "followers": item.ig_profile?.follower_count,
          "phone": item.wa_ids?.length ? item.wa_ids[0] : "",
          "last_message_date": item.last_message_date?new Date(item.last_message_date).toLocaleTimeString('ru-RU'):null,
          "source_info": item,
          "avatarUrl": avatarUrl,
          "enable_bot": !item.disable_bot
        });
      });
    }
  }

  disableBotForCurrentClient(disabled: boolean){
    this.atlas.setBotDisabled(this.selectedRow._id, disabled);
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }


}

<div class="p-3 h-full overflow-auto">
    

    <div class="mb-3" *ngFor="let row of getFlatJson(userInfo)">
        <span class="text-xs font-bold">{{ row.key }}</span><br>
        {{ row.value }} 

        <a *ngIf="row.key == 'ig_profile.username'"
            href="https://instagram.com/{{ row.value }}"
            target="_blank">
        
            <span class="pi pi-instagram"></span> Open profile   
        </a>
    </div>
</div>
<div class="flex justify-content-center w-full">
    <div class="card px-4 py-8 md:px-6 lg:px-8 m-4" style="max-width: 900px">
        <div class="flex flex-wrap mb-4">
            <div class="w-full w-12 line-height-3 text-base">
                <h2>Privacy Policy for 1nvt.com</h2>
                
                <p class="mt-0 mb-3 p-0">
                    Effective Date: 20.04.2024
                </p>
                
                <h4 class="mt-6">1. Introduction</h4>
                <p class="mt-0 mb-3 p-0">
                    Welcome to 1nvt.com. We are committed to protecting the privacy of our users. This privacy policy explains how we collect, use, protect, and share information about you, as well as your rights and choices regarding this information.
                </p>

                <h4 class="mt-6">2. Information Collection</h4>
                <p class="mt-0 mb-3 p-0">
                    We collect the following types of information:<br>
                    <strong>Email Addresses:</strong> We collect your email address to enable you to log in to the 1NVT app.<br>
                    <strong>Cookies:</strong> We use cookies to keep you logged into our app, enhancing your user experience by not requiring you to re-enter your password each time you visit our app.<br>
                    <strong>Message History:</strong> We store messages that you send and receive using the integration with Facebook and Instagram APIs.
                </p>

                <h4 class="mt-6">3. Use of Information</h4>
                <p class="mt-0 mb-3 p-0">
                    The information we collect is used solely for the purposes of providing access to our app and improving your user experience. We do not use your information for marketing or any other purposes outside of these stated.
                </p>

                <h4 class="mt-6">4. Data Protection</h4>
                <p class="mt-0 mb-3 p-0">
                    We use access control measures to protect your information from unauthorized access. Additionally, your data is securely stored on Amazon AWS.
                </p>

                <h4 class="mt-6">5. Sharing of Information</h4>
                <p class="mt-0 mb-3 p-0">
                    We do not share your information with any third parties, except as necessary to provide the service offered by our app, specifically, the integration with Facebook and Instagram as required for messaging functionality.
                </p>

                <h4 class="mt-6">6. User Rights</h4>
                <p class="mt-0 mb-3 p-0">
                    You have the right to access, correct, delete, or transfer your data. To exercise these rights, please contact us at info&#64;1nvt.com.
                </p>

                <h4 class="mt-6">7. Changes to This Privacy Policy</h4>
                <p class="mt-0 mb-3 p-0">
                    We may update this privacy policy at any time. The updated policy will be posted on the same web address. We will not notify users of these changes, so
                    you to review our privacy policy periodically to stay informed about how we are protecting your information.
                </p>
                
                <h4 class="mt-6">8. Contact Us</h4>
                <p class="mt-0 mb-3 p-0">
                    If you have any questions or concerns about our privacy policy, please contact us at info&#64;1nvt.com.
                </p>
            </div>
        </div>
    </div>
</div>
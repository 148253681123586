import { CanActivateFn, Router } from '@angular/router';
import { AtlasService } from "./atlas.service";
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const atlas = inject(AtlasService);

  console.log("authGuard", atlas.isLoggedIn());
  
  
  if(atlas.isLoggedIn()){
    return true
  }else{
    const router: Router = inject(Router);
    return router.parseUrl("/auth/login");
  }
};

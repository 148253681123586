<div id="chatContainer" class="flex flex-column h-full w-full lg:pl-2">
    
    <div id="messagesContainer" class="pr-3 pb-2 overflow-auto" #chatWindow>
        <div *ngFor="let event of events">
            
            <div 
                *ngIf="event.event_type == 'incoming_message'" 
                class="flex flex-column align-items-end mt-3 w-full"
                (contextmenu)="messageClicked(event)"
            >
                
                    <div class="flex flex-row align-items-start">

                        <div class="mt-2 flex flex-column align-items-end">
                            <div>
                                <span class="text-400 text-sm">{{ event.timestamp | date: "dd.MM.yy HH:mm" }} {{event.source_name}} {{event.status}}</span>
                                <!-- <i class="pi pi-check ml-2 text-green-400"></i> -->
                                &nbsp;
                                <span class="text-900 font-semibold">{{userInfo.name}}</span>
                            </div>


                            <!-- If it's a comment under a post post, display text here. TODO: Load post image? -->
                            <div *ngIf="event.source_event.payload?.field == 'comments' "
                                class="flex flex-column align-items-end text-700 font-medium border-1 surface-border p-3 mb-3 white-space-normal border-round" 
                                style="word-break: break-word; max-width:90%; min-width: 30%"
                            >
                                <div *ngIf="event.source_event.payload?.value?.media?.content?.caption" class="text-xs">
                                    
                                    <span [innerHTML]="event.source_event.payload?.value?.media?.content?.caption | newlineToBr"></span>

                                    <div *ngIf="event.source_event.payload?.value?.media?.content?.permalink" class="mt-2">
                                        <a href="{{ event.source_event.payload?.value?.media?.content?.permalink }}" target="_blank">View post on Instagram</a>
                                    </div>
                                </div>
                            </div>

                            <!-- If it's a reply onder a story, display media here -->
                            <div *ngIf="event.source_event.payload?.reply_to?.story" class="flex flex-column align-items-end" >
                                <!-- TODO: Get media type from facebook to avoid loading both video and audio 
                                    curl -i -X GET \
                                    "https://graph.facebook.com/v19.0/<story_id>>?fields=media_url%2Cmedia_type&access_token=<...>"
                                -->
                                <img  
                                    [src]="event.source_event.payload.reply_to?.story.url" 
                                    [class.hidden]="event.content_type != 'image'"
                                    class="attachment border-round mt-1 mb-1 border-1 surface-border"
                                >
                                <video 
                                    [class.hidden]="event.content_type != 'video'"
                                    (loadedmetadata)="event.content_type = 'video'"
                                    (error)="event.content_type = 'image'"
                                    [src]="event.source_event.payload.reply_to?.story.url" 
                                    controls  
                                    class="attachment border-round mt-1 mb-1 border-1 surface-border"
                                >
                                    Your browser does not support the video tag.
                                </video>

                            </div>

                            
                            <div *ngIf="event.message_text" 
                                class="flex flex-column align-items-end text-700 font-medium border-1 surface-border p-3 white-space-normal border-round" 
                                style="word-break: break-word; max-width:80%; min-width: 30%"
                            >
                                <span *ngIf="event.source_event.payload?.reply_to?.story">Reply to a story:</span> 
                                <span [innerHTML]="event.message_text | newlineToBr"></span>

                                <div *ngIf="event.source_event.payload?.referral" class="text-400 text-xs" >
                                    Referral: {{event.source_event.payload?.referral | json}}
                                </div>
                                
                            </div>

                            

                            
                            <div *ngFor="let a of event.attachments" class="w-full flex flex-column align-items-end w-full">
                                
                                <img *ngIf="a.type=='image'" 
                                    [src]="a.url" class="attachment mt-1 mb-1"
                                >

                                <div *ngIf="a.type=='audio'" class="w-max flex flex-column align-items-end">
                                    <audio controls>
                                        <source [src]="a.url">
                                        Your browser does not support the audio element.
                                    </audio>
                                    <div
                                        class="flex flex-column align-items-end text-700 font-medium border-1 surface-border p-3 mt-2 white-space-normal border-round" 
                                        style="word-break: break-word; max-width:80%; min-width: 30%"
                                    >
                                        Transcription: {{a.content?.text}}
                                    </div>
                                </div>
                                
                                <div *ngIf="a.type=='video'">
                                    <video [src]="a.url"  
                                        style="max-height: 20rem; max-width: 100%; min-width: 10rem" 
                                        controls class="attachment  mt-1 mb-1"
                                    >
                                        Your browser does not support the video tag.
                                    </video>    
                                </div>
                                
                                <div *ngIf="a.type=='story_mention'">
                                    <div>Story mention:</div>
                                    <img [src]="a.url" 
                                        class="attachment border-round mt-1 mb-1 attachment"
                                        [class.hidden]="a.content_type != 'image'"
                                    >
                                    <video [src]="a.url" controls 
                                        class="attachment border-round mt-1 mb-1 border-1 surface-border"
                                        [class.hidden]="a.content_type != 'video'"
                                        (loadedmetadata)="a.content_type = 'video'"
                                        (error)="a.content_type = 'image'"
                                    >
                                        Your browser does not support the video tag.
                                    </video>
                                </div>

                                

                                <!-- Shared post (and other shares?), img or video -->
                                <div *ngIf="a.type=='share' || a.type=='ig_reel'"
                                    class="flex flex-column align-items-end text-700 font-medium border-1 surface-border p-3 white-space-normal border-round" 
                                    style="word-break: break-word; max-width:90%; min-width: 30%"
                            
                                >
                                    <div *ngIf="a.payload?.url">
                                        
                                        <img [src]="a.payload.url" 
                                            class="attachment border-round mt-1 mb-1 attachment"
                                            [class.hidden]="a.content_type != 'image'"
                                        >
                                        <video [src]="a.payload.url" controls 
                                            class="attachment border-round mt-1 mb-1 border-1 surface-border"
                                            [class.hidden]="a.content_type != 'video'"
                                            (loadedmetadata)="a.content_type = 'video'"
                                            (error)="a.content_type = 'image'"
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>

                                    <div *ngIf="a.content?.caption" class="text-xs">
                                        
                                        <span [innerHTML]="a.content.caption | newlineToBr"></span>

                                        <div *ngIf="a.content?.permalink">
                                            <a href="{{ a.content.permalink }}" target="_blank">View post on Instagram</a>
                                        </div>
                                    </div>

                                    <!-- Contents of REELS -->
                                    <div *ngIf="a.payload?.title" class="text-xs">
                                            
                                        <span [innerHTML]="a.payload.title | newlineToBr"></span>

                                    </div>

                                    
                                </div>

                            </div>
                        </div>

                        <div class="ml-3 mt-1">
                            <img 
                                [src]="userInfo.avatarUrl" 
                                (error)="userInfo.avatarUrl='/assets/default_avatar.jpg'"
                                class="w-2rem h-2rem border-circle shadow-4"
                            >
                        </div>
                    </div>
                    

            </div>

            <div *ngIf="event.event_type == 'outgoing_message'" 
                class="mb-2"
                (contextmenu)="messageClicked(event)"
            >
                <div class="mt-3">
                    <div class="">
                        <!-- <i class="pi pi-check ml-2 text-green-400"></i> -->
                        <span class="text-900 font-semibold">{{event.name}}</span>
                        &nbsp;
                        <span class="text-400 text-sm"> {{event.source_name}} {{event.status}} {{ event.timestamp | date: "dd.MM.yy HH:mm" }}</span>
                        
                    </div>
                    <span class="inline-block text-left text-white font-medium bg-blue-600 p-3 white-space-normal border-round" 
                        style="word-break: break-word; max-width:90%;  min-width: 50%">
                        <span [innerHTML]="event.message_text | newlineToBr"></span>

                        <div *ngIf="event.source_event.status?.errors?.length > 0">
                            Error: 
                            <span *ngFor="let error of event.source_event.status.errors">
                                {{error.message}}:
                                {{error.error_data?.details}}
                            </span>
                        </div>
                    </span>

                    <div *ngFor="let a of event.attachments" class="w-full flex flex-column align-items-start w-full">
                                
                        <img *ngIf="a.type=='image'" 
                            [src]="a.url" class="attachment border-round mt-1 mb-1"
                        >

                        <div *ngIf="a.type=='audio'" class="w-max">
                            <audio controls>
                                <source [src]="a.url">
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        
                        <div *ngIf="a.type=='video'">
                            <video [src]="a.url"  
                                controls class="attachment border-round mt-1 mb-1 border-1 surface-border"
                            >
                                Your browser does not support the video tag.
                            </video>    
                        </div>
                        
                        <div *ngIf="a.type=='story_mention'">
                            <div>Story mention:</div>
                            <img [src]="a.url" 
                                class="attachment border-round mt-1 mb-1 attachment"
                                [class.hidden]="a.content_type != 'image'"
                            >
                            <video [src]="a.url" controls 
                                class="attachment border-round mt-1 mb-1 border-1 surface-border"
                                [class.hidden]="a.content_type != 'video'"
                                (loadedmetadata)="a.content_type = 'video'"
                                (error)="a.content_type = 'image'"
                            >
                                Your browser does not support the video tag.
                              </video>
                        </div>
                        
                    </div>

                    
                </div>
            </div>

            <div *ngIf="event.event_type == 'comment'" class="mb-2">
                <div class="mt-3 ">
                    <div class="">
                        <!-- <i class="pi pi-check ml-2 text-green-400"></i> -->
                        <span class="text-900 font-semibold">Comment by {{event.source_event.payload?.supervisor_email}}</span>
                        &nbsp;
                        <span class="text-400 text-xs">{{ event.timestamp | date: "dd.MM.yy HH:mm" }}</span>
                    </div>
                    <span class="inline-block text-left font-medium border-1 surface-border surface-200 p-3 white-space-normal border-round" style="word-break: break-word; max-width:90%;  min-width: 30%">
                        
                        {{event.message_text}}
                    
                    </span>
                    
                </div>
            </div>


            <div *ngIf="event.type == 'bot_invoke_result'" class="mb-2">
                <div class="mt-0 ">
                    
                    <p-divider align="center" class="mt-0">
                        <!-- <p-button label="Button" icon="pi pi-search" styleClass="p-button-outlined"></p-button> -->

                        <button pButton type="button" class="p-button-text text-xs p-1 text-400" icon="pi pi-align-justify" label="Logs"
                         (click)="navigate(event.status?.url)"></button>

                    </p-divider>
                </div>
            </div>

            <div *ngIf="event.type == 'consult_supervisor'" class="mb-2">
                <div class="mt-3">
                    <div class="">
                        <!-- <i class="pi pi-check ml-2 text-green-400"></i> -->
                        <span class="text-900 font-semibold">Request to the Supervisor</span>
                        &nbsp;
                        <span class="text-400 text-sm">{{ event.timestamp | date: "dd.MM.yy HH:mm" }}</span>

                    </div>
                    <span class="inline-block text-left font-medium bg-pink-700 text-white p-3 white-space-normal border-round" style="word-break: break-word; max-width:90%;  min-width: 30%">
                        {{event.payload.text}}
                    </span>
                </div>
            </div>

        </div>
    </div>
    <div class="pt-3 lg:pr-3 md:pr-3 flex flex-row align-items-center mt-auto border-top-1 surface-border gap-3">
        
        <textarea rows="1" id="message" pInputTextarea [autoResize]="true"  placeholder="Type a message" class="flex-1 w-full border-round" [(ngModel)]="inputFieldTextContent" (keydown.enter)="onEnter($event);"></textarea>

        <button pButton pRipple icon="pi pi-send" class="w-3rem h-3rem" (click)="sendMessage()"></button>
        
    </div>
</div>


<p-overlayPanel #op styleClass="w-full sm:w-30rem">
    <ng-template pTemplate>
        <button *ngFor="let emoji of emojis;" pButton pRipple (click)="op.hide(); onEmojiSelect(emoji)" type="button" [label]="emoji" class="p-2 p-button-text text-2xl"></button>
    </ng-template>
</p-overlayPanel>

<!--
<div class="col-12 align-items-center justify-content-center">
    <div *ngFor="let event of events" >
        <p-divider layout="horizontal" align="left">

            <span class="vertical-align-middle text-sm text-color-secondary">{{event.event_type}}: {{ event.timestamp*1000 | date: "dd.MM.yy HH:mm" }}</span>
        </p-divider>
    
        <p class="line-height-3 m-0">{{event.message_text}}</p>
    </div>
</div>-->
import { Component } from '@angular/core';
import { TimelineComponent } from '../timeline/timeline.component';
import { ProfileComponent } from '../profile/profile.component';
import { SplitterModule } from 'primeng/splitter';
import { AtlasService } from '../atlas.service';
import { ActivatedRoute } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { Input } from '@angular/core';
import { OnChanges, SimpleChanges } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-client',
  standalone: true,
  imports: [
    TimelineComponent,
    ProfileComponent,
    SplitterModule,
    ButtonModule,
    CommonModule,
    TabViewModule,
    SidebarModule
  ],
  templateUrl: './client.component.html',
  styleUrl: './client.component.scss'
})
export class ClientComponent implements OnChanges {
  @Input() userId:any|null = null;
  @Input() userInfo:any = {};

  
  showProfileSidebar: boolean=false;
  events:any[]=[];
  
  constructor(
    private atlas: AtlasService ,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private title: Title,
  ){}


  async ngOnInit() {
    this.route.params.subscribe(async params => {
      this.userId = params['id'];

      this.userInfo.avatarUrl="/assets/default_avatar.jpg";
      this.userInfo.name="Loading...";

      this.userInfo = await this.atlas.getClient(this.userId);

      if(this.userInfo.ig_profile?.profile_pic)
        this.userInfo.avatarUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.userInfo.ig_profile.profile_pic);
      else
        this.userInfo.avatarUrl="/assets/default_avatar.jpg";


      this.title.setTitle(this.userInfo.name + " | BotAdmin");

      console.log("Client, Current user: ", this.userInfo.name, this.userId);
    });
    
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['userId'] && changes['userId'].currentValue) {

    }
  } 

  isScreenLarge(){
    return window.innerWidth >= 768;
  }

  getSidebarWidth(): string {
    return 'calc(100% - 50px)';
  }

}

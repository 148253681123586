import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AtlasService } from "../atlas.service";
import { TableModule } from 'primeng/table'

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, TableModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  constructor(
    private atlas: AtlasService ,
    private route: ActivatedRoute,
  ) {}

  @Input() userInfo: any[] = [];
  @Input() userId: string|null = null;

  ngOnInit() {
    
  }

  // Function to convert nested JSON to a flat array of key-value pairs
  getFlatJson(jsonObject: any, prefix: string = ''): any[] {
    let result: any[] = [];
    Object.keys(jsonObject).forEach(key => {
      const value = jsonObject[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        result = [...result, ...this.getFlatJson(value, prefixedKey)];
      } else {
        result.push({ key: prefixedKey, value });
      }
    });
    return result;
  }
}

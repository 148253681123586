import { OnInit } from '@angular/core';
import { Component } from '@angular/core';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'Bot Admin',
                icon: 'pi pi-fw pi-box',
                items: [
                    {
                        label: 'Clients',
                        icon: 'pi pi-fw pi-box',
                        routerLink: ['/clients']
                    },
                    {
                        label: 'Settings',
                        icon: 'pi pi-fw pi-cog',
                        routerLink: ['/settings']
                    },
                ]
            },
        ];
    }
}

import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { authGuard } from './auth.guard';

import { ClientsComponent } from './clients/clients.component';
import { ClientComponent } from './client/client.component';
import { SettingsComponent } from './settings/settings.component';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    { path: 'privacy-policy', component: PrivacyPolicyComponent},

    {path: '', component: AppLayoutComponent,
        children: [
            
            { path: 'affiliates', canActivate: [authGuard],  data: { breadcrumb: 'Affiliates' }, loadChildren: () => import('../app/affiliates/affiliates.module').then(m => m.AffiliatesModule) }, 

            { path: 'clients',  data: { breadcrumb: 'Clients' }, canActivate: [authGuard], component: ClientsComponent, children:[
                { path: ':id',  data: { breadcrumb: 'Client' }, canActivate: [authGuard], component: ClientComponent},
            ]},

            { path: 'settings',  data: { breadcrumb: 'Settings' }, canActivate: [authGuard], component: SettingsComponent},

            { path: '', redirectTo: 'clients', pathMatch: 'full'},
        ]
    },
    
    
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
    { path: 'notfound',  data: { breadcrumb: 'notfound' }, canActivate: [authGuard], loadComponent: () => import('./not-found/not-found.component').then(mod => mod.NotFoundComponent)},
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

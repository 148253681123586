import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Input } from '@angular/core';
import { AtlasService } from "../atlas.service";
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ActivatedRoute } from '@angular/router';
import { ElementRef, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { NewlineToBrPipe } from '../newline-to-br.pipe';

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [
    ButtonModule,
    DividerModule,
    CommonModule,
    TagModule,
    InputTextModule,
    FormsModule,
    OverlayPanelModule,
    InputTextareaModule,
    NewlineToBrPipe
  ],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent implements OnChanges {
  @ViewChild('chatWindow') private chatWindow!: ElementRef;

  @Input() userInfo: any = {};
  @Input() userId: string|null = "";

  events: any = [];
  debugEvents = [
    {
      timestamp: 123123123,
      message_text: "Hello!",
      event_type: "wa_incoming",
    },
    {
      timestamp: 123123123,
      message_text: "Are you here? 😀",
      event_type: "wa_incoming",
    },
    {
      timestamp: 123123123,
      message_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      event_type: "wa_incoming",
    },
    {
      timestamp: 123123123,
      message_text: "Hey! 😀",
      event_type: "wa_outgoing",
      name: "Bot"
    },
    {
      name: "Bot",
      timestamp: 123123123,
      message_text: "Thank you for your message! Here's a link: https://www.google.com/search?q=user+avatar+placeholder&sca_esv=594ee2364d4abedf&udm=2&biw=1078&bih=1230&ei=5VMiZpmUEt2D9u8P4_WlqAc&ved=0ahUKEwjZmpbllM6FAxXdgf0HHeN6CXUQ4dUDCBA&uact=5&oq=user+avatar+placeholder&gs_lp=Egxnd3Mtd2l6LXNlcnAiF3VzZXIgYXZhdGFyIHBsYWNlaG9sZGVyMgUQABiABDIHEAAYgAQYGEiBNVCcDljSMnACeACQAQCYAdgBoAHsG6oBBjAuMTIuNrgBA8gBAPgBAZgCEqACkByYAwCIBgGSBwYwLjEwLjigB-c1&sclient=gws-wiz-serp#vhid=RxCi5T1_xIodAM&vssid=mosaic ",
      event_type: "wa_outgoing",
    },
    {
      timestamp: 123123123,
      message_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      event_type: "wa_outgoing",
      name: "Timur"
    },
    {
      timestamp: 123123123,
      message_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      event_type: "comment",
      name: "Timur"
    },

  ];

  mongodbEvents: any = [];
  

  inputFieldTextContent: string = "";


  constructor(
    private atlas: AtlasService ,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
  ) {}

  navigate(url: any){
    window.open(url, '_blank')
  }


  ngOnInit() {
    console.log("Timeline init");
    this.route.params.subscribe(params => {
      this.userId = params['id']; // Get the parameter 'id'
      console.log("Timeline, Current user ID: ", this.userId);

      this.loadEvents();
      
    }); 
    
  }

  async ngOnChanges(changes: SimpleChanges) {
  
  } 

  scrollToBottom(): void {
    const element = this.chatWindow.nativeElement;
    this.renderer.setProperty(element, 'scrollTop', element.scrollHeight);
  }

  onInputFocus(){
    
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit events : ",this.events);
    this.scrollToBottom();
  }

  private lastKnownEventCount = 0;
  ngAfterViewChecked() {
    if (this.events.length !== this.lastKnownEventCount) {
      this.scrollToBottom();
      this.lastKnownEventCount = this.events.length; // update the known count
    }
  }

  emojis = [
    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😇', '😉', '😊', '🙂', '🙃', '😋', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '🤪', '😜', '😝', '😛',
    '🤑', '😎', '🤓', '🧐', '🤠', '🥳', '🤗', '🤡', '😏', '😶', '😐', '😑', '😒', '🙄', '🤨', '🤔', '🤫', '🤭', '🤥', '😳', '😞', '😟', '😠', '😡', '🤬', '😔',
    '😟', '😠', '😡', '🤬', '😔', '😕', '🙁', '😬', '🥺', '😣', '😖', '😫', '😩', '🥱', '😤', '😮', '😱', '😨', '😰', '😯', '😦', '😧', '😢', '😥', '😪', '🤤'
  ];

  onEmojiSelect(emoji: string) {
    this.inputFieldTextContent += emoji;
  }


  onEnter(event:any){
    if (!event.ctrlKey && !event.shiftKey){
      this.sendMessage();
      event.preventDefault();
    }
  }

  sendMessage(){
    if (!this.inputFieldTextContent)
      return;

    this.atlas.postSupervisorResponse(this.userId, this.inputFieldTextContent);

    this.inputFieldTextContent = "";
  }

  timelineChangeStream: any|null = null;

  

  async loadEvents(){

    this.mongodbEvents = await this.atlas.getTimeline(this.userId);
    console.log("Timeline: ",this.userId,this.mongodbEvents)

    this.parseEvents();

    var t=this;

    this.atlas.watchForTimelineUpdates(function(change){
      if (change.operationType === 'insert') {
        console.log("New timeline event inserted:", change.fullDocument);
        var newEvent = change.fullDocument;
        
        console.log("This is a new event for client: ",newEvent.client_id.toString());
        console.log("Currently active client: ",t.userId?.toString());

        if (newEvent.client_id.toString() != t.userId?.toString()){
          var e = t.parseEvent(newEvent);
          t.messageService.add({ key: 'tst', severity: 'success', summary: e.event_type, detail: e.message_text});
          return;
        }

        t.mongodbEvents.push(newEvent);
        t.events.push(t.parseEvent(newEvent));
        console.log("New event added to the timeline: ",t.events);
      }else if (change.operationType === 'delete'){
        console.log("Timeline event deleted:", change.documentKey);
        t.events = t.events.filter(function( obj: any ) {
          return obj._id && ( obj._id.toString() !== change.documentKey._id.toString() );
        });
      }
      
    });

  }

  

  parseEvent(event:any){
    var new_timeline_event = {
      _id: event._id,
      event_type: "",
      timestamp: event.created,
      message_text: "",
      name: "EvoBot",
      source_name: "Whatsapp",
      status: "",
      attachments: Array(),

      source_event: event
    };
    
    if(event.type == "wa_outgoing" || event.type == "wa_incoming"){
      event.source_name = "Whatsapp";

      if (event.type == "wa_outgoing")
        new_timeline_event.event_type = "outgoing_message";
      else 
        new_timeline_event.event_type = "incoming_message";

      if (event.payload.type == "text")
        new_timeline_event.message_text = event.payload.text.body
      else if (event.payload.type == "video"){
        new_timeline_event.message_text = event.payload.video.caption;
        new_timeline_event.attachments.push({
          url: this.sanitizer.bypassSecurityTrustResourceUrl(event.payload.video.link),
          type: event.payload.type
        });
      }else if (event.payload.type == "audio"){
        //new_timeline_event.message_text = event.payload.audio.content.text;
        new_timeline_event.attachments.push({
          url: this.sanitizer.bypassSecurityTrustResourceUrl(event.payload.audio.url),
          type: event.payload.type,
          mime_type: event.payload.audio.content.mime_type,
          content:{
            text: event.payload.audio.content.text
          }
        });
      }else if (event.payload.type == "image"){
        new_timeline_event.message_text = (event.payload.image?.caption ? event.payload.image?.caption : "")+" | TODO: Load image by id";
        // new_timeline_event.attachments.push({
        //   url: this.sanitizer.bypassSecurityTrustResourceUrl(event.payload.image.link),
        //   type: event.payload.type
        // });
      }

    }else if(event.type == "ig_outgoing" || event.type == "ig_incoming"){
      new_timeline_event.source_name = "Instagram";

      if (event.type == "ig_outgoing"){
        new_timeline_event.status=event.status.status;
        new_timeline_event.event_type = "outgoing_message";
      }else {
        new_timeline_event.event_type = "incoming_message";
      }

      if (event.payload?.text)
        new_timeline_event.message_text = event.payload.text

      if (event.payload?.reply_to){
        if(event.payload.reply_to.story){
          new_timeline_event.attachments.push({
            url: this.sanitizer.bypassSecurityTrustResourceUrl(event.payload.reply_to.story.url),

            comment: "Reply to story " + event.payload.reply_to.story.id
          })
        }else if (event.payload.reply_to.mid){
          // TODO: find message by mid
          new_timeline_event.message_text += " | This is a reply to message " + event.payload.reply_to.mid;
        }else{
          new_timeline_event.message_text += "<br>unknown reply_to:" + JSON.stringify(event.payload.reply_to, null, 2);
        }
      }

      if (event.payload?.attachments){
        for (var attachment of event.payload.attachments){

          new_timeline_event.attachments.push({
            url: this.sanitizer.bypassSecurityTrustResourceUrl(attachment.payload.url),
            type: attachment.type,
            content: attachment.content,
            payload: attachment.payload
          })
          
        }
      }

    }else if(event.type == "ig_change" && event.payload.field == "comments"){
      new_timeline_event.source_name = "Instagram";
      new_timeline_event.event_type = "incoming_message";
      new_timeline_event.name= event.payload.value.from.username;
      new_timeline_event.message_text = "Comment for " + 
        event.payload.value.media.media_product_type + " " + event.payload.value.media.id +": \n"+
        event.payload.value.text

    }else if(event.type == "bot_invoke_request"){
      new_timeline_event.source_name = "AI";
      new_timeline_event.event_type = "hidden";
      new_timeline_event.name="AI";
      new_timeline_event.message_text = JSON.stringify(event.payload, null, 2);

    }else if(event.type == "bot_invoke_result"){
      new_timeline_event = event;
    }else if(event.type == "supervisor_message"){
      new_timeline_event.source_name = "";
      new_timeline_event.event_type = "comment";
      new_timeline_event.name="Supervisor";
      new_timeline_event.message_text = event.payload.text;
    }else if(event.type == "consult_supervisor"){
      new_timeline_event = event;
    }else{
      // Unparsed event
      new_timeline_event.source_name = "";
      new_timeline_event.event_type = "comment";
      new_timeline_event.name="Unknown message";
      new_timeline_event.message_text = JSON.stringify(event);
    }

    return new_timeline_event;
  }

  parseEvents(){
    this.events=[];
    this.mongodbEvents?.forEach((event:any) => {
      this.events.push(this.parseEvent(event));
    })  
  }

  messageClicked(event: any){
    console.log("Event:",event);
  }

}

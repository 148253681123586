import { Injectable, assertInInjectionContext } from '@angular/core';
import { Router } from '@angular/router';
import * as Realm from "realm-web";
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
  
})
export class AtlasService {
  realmApp: Realm.App;
  
  constructor (
    private router: Router,
    private messageService: MessageService
  ) { 
    this.realmApp = new Realm.App({ id: "evoaffiliate-vdjdm" })
  }

  async ngOnInit(){
    
  }

  async loginWithEmailPassword(email: string, password: string){
    const credentials = Realm.Credentials.emailPassword(email, password);
    try{
      const user = await this.realmApp.logIn(credentials);
      console.assert(user.id === this.realmApp.currentUser?.id);
    }catch(err: any){
      console.log("Error logging in:", err);
      this.router.navigateByUrl("/auth/login");
    }
  }

  async find(filter: any){
    return await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evolution").collection("affiliates").find({});
  }

  clientsPipeline2 = [
    {
        $lookup: {
            from: "client_timeline",
            localField: "_id",
            foreignField: "client_id",
            as: "timeline"
        }
    },
    {
        $unwind: "$timeline"
    },
    {
        $group: {
            _id: "$_id",
            name: { $first: "$name" },
            phone: { $first: "$phone" },
            lastCreated: { $max: "$timeline.created" },
            count: { $count: {} }
        }
    },
    {
        $sort: { lastCreated: -1 }
    }
  ];


  currentClient: any|null = null;


  clientsPipeline = [
    {
      $sort: { last_message_date: -1 }
    },
    {
      $limit: 200
    }
  ];

  async getAllClients(){

    const result = await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("clients").aggregate(this.clientsPipeline);
    
    return result;
  }

  async getClient(id: string){

    const result = await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("clients").find({
      _id: new Realm.BSON.ObjectId(id)
    });
    console.log(result);
    
    return result?result[0]:{};
  }



  clientsFromAdsPipeline = [
    {
      $lookup: {
        from: "client_timeline",
        localField: "_id",
        foreignField: "client_id",
        as: "timeline_messages"
      }
    },
    {
      $match: {
        "timeline_messages.payload.text.body": {
          $regex: ".*Please send this message without modification to start chat.*"
        }
      }
    },
    {
      $sort: { created: -1 }
    },
    {
      $limit: 200
    }
  ];
  
  async getClientsFromAds() {
    console.log("Getting clients from ads");
    const result = await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("clients").aggregate(
      this.clientsFromAdsPipeline
    );

    console.log(result);
  
    return result;
  }



  clientsChangestream: any|null = null;
  async watchForClientsUpdates(onUpdate: (input: any) => void){

    const isFirstRun = !this.clientsChangestream;

    this.clientsChangestream = await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("clients").watch(this.clientsPipeline);

    if (isFirstRun){
      console.log("Startin watching thread for clients updates")
      for await (const change of this.clientsChangestream) {
        console.log("Received a change to the clients list:", change);
        if (change.operationType === 'insert') {
          console.log("New client!:", change.fullDocument);
          onUpdate(change.fullDocument);
        }
      }
    }
  }

  async altegio_getUserInfo(phone: string){
    if (!phone)
      return "";

    const res =  await this.realmApp.currentUser?.functions['get_client_info'](phone);
    console.log("User info",phone, res)
    var userInfo = {
      name: res.data && res.data.length ? res.data[0].name:"",
      phone: phone,
      altegio_id: res.data[0]?.id,
      avatarUrl: "https://cdn2.vectorstock.com/i/1000x1000/44/11/default-avatar-photo-placeholder-icon-grey-vector-38594411.jpg"
    };
    return userInfo;
  }

  async getTimeline(id: any){

    return await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("client_timeline").aggregate([
      { $match: {"client_id": new Realm.BSON.ObjectId(id)} },
      { $sort: { "created": 1 } }
    ]);

  }

  timelineChangeStream: any|null = null;
  onTimelineChangeCallback:any|null = null;

  async watchForTimelineUpdates(onUpdate: (input: any) => void){ 
    
    this.onTimelineChangeCallback = onUpdate;

    if (!this.timelineChangeStream){
      this.timelineChangeStream = await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("client_timeline").watch();
      
      // Start watching thread
      while(true){
        try{
          console.log("Starting watching timeline changes ");
          for await (const change of this.timelineChangeStream) {
            console.log("Received a change to the client's timeline:", change);
            this.onTimelineChangeCallback(change);
          }
        }catch(err: any){
          console.error("Error watching timeline changes:", err);
          await new Promise(resolve => setTimeout(resolve, 3000));
          console.log("Creating new change stream");
          this.timelineChangeStream = await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("client_timeline").watch();
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }
  }


  async postSupervisorResponse(clientId: any, message: string){
    try{
      const res= await this.realmApp.currentUser?.mongoClient("mongodb-atlas")
        .db("evobot")
        .collection("client_timeline")
        .insertOne({
          type: 'supervisor_message',
          payload: {
            text: message,
            supervisor_email: this.realmApp.currentUser?.profile.email
          },
          client_id: new Realm.BSON.ObjectId(clientId),
          created: new Date()
        });

      if (!res?.insertedId)
        throw new Error("No Record Updated");
      //this.messageService.add({ key: 'tst', severity: 'success', summary: 'Message posted', detail: 'Record ID: '+res?.insertedId });
    }catch(err: any){
      this.messageService.add({ key: 'tst', severity: 'error', summary: err.error, detail: err.errorCode });
      console.log("Error URL:", err.link);
      return err;
    }
  }


  // const result = await this.realmApp.currentUser?.mongoClient("mongodb-atlas").db("evobot").collection("clients").find({
  //   _id: new Realm.BSON.ObjectId(id)
  // });

  async setBotDisabled(id: string, disabled: boolean){
    try{
      const res= await this.realmApp.currentUser?.mongoClient("mongodb-atlas")
        .db("evobot")
        .collection("clients")
        .updateOne(
          {
            _id: new Realm.BSON.ObjectId(id)
          }, 
          {
            $set: { "disable_bot": disabled }
          }
        );

      if (res?.modifiedCount === 0 && !res?.upsertedId)
        throw new Error("No Record Updated");

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Bot disabled: '+disabled, detail: 'Record Updated' });

      return res;

    }catch(err: any){
      this.messageService.add({ key: 'tst', severity: 'error', summary: err.error, detail: err.errorCode });
      console.log("Error URL:", err.link);
      return err;
    }
  }



  async updateOne(filter: any, update: any, options?: any){
    try{
      const res= await this.realmApp.currentUser?.mongoClient("mongodb-atlas")
        .db("evolution")
        .collection("affiliates")
        .updateOne(filter, update,options);

      if (res?.modifiedCount === 0 && !res?.upsertedId)
        throw new Error("No Record Updated");

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Record Updated', detail: 'Record Updated' });

      return res;

    }catch(err: any){
      this.messageService.add({ key: 'tst', severity: 'error', summary: err.error, detail: err.errorCode });
      console.log("Error URL:", err.link);
      return err;
    }
  }

  async deleteOne(filter: any){
   
    try{
      const res= await this.realmApp.currentUser?.mongoClient("mongodb-atlas")
        .db("evolution")
        .collection("affiliates")
        .deleteOne(filter);

      if (!res)
        throw new Error("Failed to delete");

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Record Deleted', detail: 'Record Deleted' });

      return res;

    }catch(err: any){
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error Deleting Record', detail: err.message });
      console.log("Error URL:", err.link);
      return err;
    }
  }

  isLoggedIn(){
    return this.realmApp.currentUser !== null;
  }

  logOut(){
    this.realmApp.currentUser?.logOut();
    this.router.navigateByUrl("/auth/login");
  }

  getUserName(){
    return this.realmApp.currentUser?.profile.email;
  }
}

import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AtlasService } from '../atlas.service';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    constructor(public layoutService: LayoutService,
        public atlas: AtlasService ) { }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    logOut(){
        this.atlas.logOut();
    }
}

    <!-- <div class="flex flex-column w-full">
        <div class="flex align-items-center pb-2 lg:pb-4 border-bottom-1 surface-border">
            <div class="relative flex align-items-center mr-3">
                <img [src]="userInfo.avatarUrl" alt="{{userInfo.name}}" class="w-4rem h-4rem border-circle shadow-4">
            </div>
            <div class="mr-2">
                <span class="text-900 font-semibold block">{{userInfo.name}}</span>
                <span class="text-700">Last active 1 hour ago</span>
            </div>
            <div class="flex align-items-center ml-auto">
                <button (click)="showProfileSidebar=true"  *ngIf="!isScreenLarge()" pButton pRipple type="button" icon="pi pi-info" class="p-button-rounded p-button-outlined p-button-secondary mr-6" ></button>
            </div>
        </div>
    </div> -->


    <!-- UGLY SOLUTION: this button appears on top of thetoolbar on mobiles -->
        <button *ngIf="!isScreenLarge()" 
            style="top: 0.75rem; position: fixed; right:4rem;"
            (click)="showProfileSidebar=true" pButton pRipple type="button" icon="pi pi-info" 
            class="p-button-rounded p-button-outlined p-button-secondary">
        </button>

   
    <div class="flex flex-column flex-grow-1 w-full h-full overflow-hidden border-top-1 surface-border" >
        
        <!-- panel -->
        <p-splitter *ngIf="isScreenLarge()" [style]="{ height: '100%', border: 0 }" class="h-full w-full" layout="horizontal" [panelSizes]="[66,34]" [minSizes]="[10,10]">
            <ng-template pTemplate>
                <app-timeline [userInfo]="userInfo" [userId]="userId" class="w-full h-full"></app-timeline>
                <!-- <app-timeline class="w-full h-full"></app-timeline> -->
            </ng-template>
            <ng-template pTemplate>
                <app-profile [userInfo]="userInfo"></app-profile>
            </ng-template>
        </p-splitter>

        <!-- mobile version -->
        <div *ngIf="!isScreenLarge()" class="w-full h-full">
            <app-timeline [userInfo]="userInfo" [userId]="userId"  class="w-full h-full"></app-timeline>
            <!-- <app-timeline class="w-full h-full"></app-timeline> -->
            <p-sidebar 
                [(visible)]="showProfileSidebar" 
                [style]="{ width: getSidebarWidth() }" 
                position="right" 
                [baseZIndex]="10000"
                [showCloseIcon]="true"
                [modal]="false"
                [blockScroll]="true"
            >
                <app-profile [userId]="userId" [userInfo]="userInfo"></app-profile>                
            </p-sidebar>
        </div>
        <!--
        <p-tabView  *ngIf="!isScreenLarge()" class="flex flex-column flex-grow-1 h-full w-full"  orientation="left">
        
            <p-tabPanel header="Timeline" class="h-full w-full flex flex-grow-1 line-height-3 m-0">
                
               
            </p-tabPanel>
            <p-tabPanel header="Profile" class="h-full w-full flex flex-grow-1 line-height-3 m-0">
                <app-profile [userInfo]="userInfo"></app-profile>
            </p-tabPanel>
        </p-tabView>
        -->
    </div>
    

    